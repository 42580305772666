import en from './shared/en.json'

export let LOCAL_STRINGS = en

const hostname = (window && window.location && window.location.hostname)
  .replace('https://', '')
  .replace('http://', '')
  .toLowerCase()

function determineApiEndpoint(host) {
  switch (host) {
    case 'cloud.quantifeel.com': //prod
      return 'https://api.quantifeel.com/v1/'
    case 'dev-cloud.quantifeel.com':
      return 'https://dev-api.quantifeel.com/v1/'
    case 'localhost':
      return 'https://dev-api.quantifeel.com/v1/'
     // return "http://localhost:8080/v1/"
    case 'usw2-dev-sst-pr-preview-com.s3.amazonaws.com':
      return 'https://dev-api.quantifeel.com/v1/'
    case 'stage-cloud.quantifeel.com':
      return 'https://stage-api.quantifeel.com/v1/'
    case 'clouddev.quantifeel.com': //old dev
      return 'https://apidev.quantifeel.com/v1/'
    default:
      console.error("Couldn't determine API endpoint")
      return ''
  }
}

function determineSsoLoginEndpoint(host) {
  let domain = ''
  let appClientId = ''
  let protocol = 'https://'
  let redirectUrl = ''

  switch (host) {
    case 'cloud.quantifeel.com': //prod
      domain = 'quantifeel'
      break
    case 'dev-cloud.quantifeel.com':
    case 'usw2-dev-sst-pr-preview-com.s3.amazonaws.com':
    case 'localhost':
      domain = 'dev-quantifeel'
      appClientId = '9a5hcquhni586h9qc2i2knerl'
      redirectUrl = 'dev-cloud.quantifeel.com'
      // protocol='http://'
      // redirectUrl = 'localhost:3000';
      break
    case 'stage-cloud.quantifeel.com':
      domain = 'stage-quantifeel'
      break
    default:
      console.error("Couldn't determine host")
      break
  }

  return (
    `https://${domain}.auth.us-west-2.amazoncognito.com/login` +
    `?client_id=${appClientId}` +
    `&response_type=code` +
    `&scope=aws.cognito.signin.user.admin+openid+profile` +
    `&redirect_uri=${protocol}${redirectUrl}`
  )
}

function determineFeatures(hostname) {
  let feature = {
    sso: false,
  }
  switch (hostname) {
    case 'cloud.quantifeel.com': //prod
      break
    case 'dev-cloud.quantifeel.com':
    case 'usw2-dev-sst-pr-preview-com.s3.amazonaws.com':
    case 'localhost':
      feature.sso = true
      break
    case 'stage-cloud.quantifeel.com':
      break
    default:
      break
  }

  return feature
}

/**
 * Define ranges and colors, from data stream values (pressure, topLoad, etc).
 *
 * In future iterations, we'll likely get these values from an external source.
 */
export const SAFE_PRESSURE_AND_COLOR_RANGES = [
  {
    color: '#31C531',
    min: 0,
    max: 30
  },
  {
    color: '#F5D218',
    min: 30,
    max: 45,
  },
  {
    color: '#FF7A00',
    min: 45,
    max: 60
  },
  {
    color: '#F60000',
    min: 60,
    max: Infinity
  }
];

export const BASE_SST_API_URL = determineApiEndpoint(hostname)
export const SST_SSO_LOGIN = determineSsoLoginEndpoint(hostname)
export const SST_FEATURES = determineFeatures(hostname)

export const SST_API_PATH_CUSTOMERS = 'customers'
export const SST_API_PATH_TIMEZONES = 'timezones'
export const SST_API_PATH_USERS_LOGIN = 'users/login'
export const SST_API_PATH_USERS_LOGIN_SSO = SST_API_PATH_USERS_LOGIN + '/sso'
export const SST_API_PATH_USERS_REFRESH_TOKEN = 'users/refresh-token'
export const SST_API_PATH_USERS_LOGOUT = 'users/logout'
export const SST_API_PATH_USERS_REQUEST_PASSWORD_RESET =
  'users/password/requestReset'
export const SST_API_PATH_USERS_RESET_PASSWORD = 'users/password/reset'
export const SST_API_PATH_USERS = 'users'
export const SST_API_PATH_SENSORS = 'sensors'
export const SST_API_PATH_VIBRATION = 'vibration'
export const SST_API_PATH_DATA = 'data'
export const SST_API_PATH_LINES = 'lines'
export const SST_API_PATH_LINE_SETTINGS = 'line-settings'
export const SST_API_PATH_MACHINE_CENTERS = 'machine-centers'
export const SST_API_PATH_MACHINE_CENTER_TYPES = 'machine-center-types'
export const SST_API_PATH_PLANTS = 'plants'
export const SST_API_PATH_RUNS = 'runs'
export const SST_API_PATH_ROLES = 'roles'
export const SST_API_PATH_HUBS = 'hubs'
export const SST_API_PATH_DRONES = 'drones'
export const SST_API_PATH_PARTS = 'machineparts'
export const SST_API_PATH_FAULTS = 'faults'
export const SST_API_PATH_SYMPTOMS = 'symptoms'
export const SST_API_PATH_SEVERITY = 'severities'
export const SST_API_PATH_DIAGNOSIS = 'diagnoses'
export const SST_API_PATH_DOWNLOAD = 'download'
export const SST_API_PATH_CONFIGURATIONS = 'configurations'
export const SST_API_PATH_DASHBOARDS = 'dashboards'
export const SST_API_PATH_DOCUMENTATION = 'documentation'
export const SST_API_PATH_RUNS_SYNC_TO_DB = 'syncToDatabase'
export const SST_API_PATH_RUNS_EXTRACT = 'extract'
export const SST_API_PATH_RUNS_DECOMPRESS = 'generate-uncompressed-pressure'
export const SST_API_PATH_RUNS_WIDGET = 'widgets'
export const SST_API_PATH_ERROR_REPORTS = 'error-report'
export const SST_API_PATH_SOFTWARE = 'software'
export const SST_API_PATH_RELEASES = 'releases'
export const SST_API_PATH_LINE_SUMMARIES = 'linesummaries'
export const SST_API_PATH_LINEMAP_IMAGE = 'linemapImage'
export const SST_API_PATH_SUPPORT_EMAIL = 'support/email'
export const SST_API_QUERY_INCLUDE_PLANT_NAME = 'includePlantName'
export const SST_API_QUERY_ONLY_LATEST = 'onlyLatest'
export const SST_API_QUERY_RETURN_DATA_TYPE = 'dataType'
export const SST_API_QUERY_RETURN_DATA_FORMAT = 'dataFormat'
export const SST_API_QUERY_RETURN_DATA_EXPORT = 'exportTo'
export const SST_API_QUERY_RETURN_BASE64 = 'base64'
export const SST_API_QUERY_ONLY_WITH_DOWNLOAD_AVAILABLE =
  'onlyWithDownloadAvailable'
export const SST_API_QUERY_LIMIT = 'limit'
export const SST_API_QUERY_INCLUDE_DIAGNOSES = 'includeDiagnoses'

export const SST_PAGE_LIST_HUBS = 'list-hubs'
export const SST_PAGE_LIST_LINES = 'list-lines'
export const SST_PAGE_VIEW_LINE = 'view-line'
export const SST_PAGE_EDIT_LINE = 'edit-line'
export const SST_PAGE_LINE_SETTINGS = 'line-settings'
export const SST_PAGE_LIST_RUNS = 'list-runs'
export const SST_PAGE_LIST_LINE_CONFIGURATION = 'list-line-configurations'
export const SST_PAGE_LIST_MACHINE_CENTERS = 'list-machine-centers'
export const SST_PAGE_VIEW_MACHINE_CENTER = 'view-machine-center'
export const SST_PAGE_CREATE_MACHINE_CENTER = 'create-machine-center'
export const SST_PAGE_EDIT_MACHINE_CENTER = 'edit-machine-center'
export const SST_PAGE_MACHINE_CENTER_SETTINGS = 'machine-center-settings'
export const DATA_TYPE_TIME_WAVEFORM = 'timewaveform'
export const DATA_TYPE_FFT = 'fft'
export const DATA_FORMAT_CSV = 'csv'
export const DATA_FORMAT_JSON = 'json'
export const DATA_FORMAT_NONE = 'none'
export const DATA_EXPORT_IBA = 'iba'
export const DATA_EXPORT_CSV = 'csv'

export const LOCAL_STORAGE_KEY_AUTH_TOKEN = 'sst-authentication-token'
export const LOCAL_STORAGE_KEY_LOGIN_DETAILS = 'sst-login-details'

export const SST_DIAGNOSIS_GREEN_COLOR = '#4fb056'
export const SST_DIAGNOSIS_YELLOW_COLOR = '#f6cf0b'
export const SST_DIAGNOSIS_RED_COLOR = '#e3242d'
export const SST_DIAGNOSIS_UNKNOWN_COLOR = 'purple'

export const SST_DIAGNOSIS_GREEN_NAME = 'Good'
export const SST_DIAGNOSIS_YELLOW_NAME = 'Concerning'
export const SST_DIAGNOSIS_RED_NAME = 'Critical'
export const SST_DIAGNOSIS_UNKNOWN_NAME = 'Monitoring'

export const CURSOR_MOVABLE = 'Default'
export const CURSOR_HARMONIC = 'Harmonic'
export const CURSOR_SIDEBAND = 'Sideband'

export const HARMONIC_REFERENCE_COLOR = '#FF8C00'
export const HARMONIC_SIDEBAND_COLOR = '#222'
export const ERROR_TEXT_COLOR = '#cc0000'

export const ERROR_REPORT_UPLOAD_COMPLETE = 2

export const SST_SUPPORT_EMAIL = "support@smartskintech.com"
export const SST_SUPPORT_PHONE = "+1 (855) 210-9006"
export const SST_PRIVACY_POLICY_URL = {
  "english": "https://www.iubenda.com/privacy-policy/30996457",
  "french": "https://www.iubenda.com/privacy-policy/81945171",
}

export const SST_COMPANY_NAME = "Smart Skin Technologies Inc."
export const SST_COPYRIGHT_LABEL = `© ${process.env.CURRENT_YEAR} ${SST_COMPANY_NAME}`;