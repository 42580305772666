import {axios} from '../client';

import {
  SST_API_PATH_LINES,
  SST_API_PATH_MACHINE_CENTERS,
} from './../../Constants'


export const getMachineCenter = ({queryKey}) => {
  const [, {machineCenterId}] = queryKey;
  return axios.get(`${SST_API_PATH_MACHINE_CENTERS}/${machineCenterId}`).then(res => res.data);
}

export const getMachineCenters = ({queryKey}) => {
  const [, {lineId}] = queryKey;
  return axios.get(`${SST_API_PATH_LINES}/${lineId}/machine-centers`).then(res => res.data);
}

export const getMachineCenterRuns = ({queryKey}) => {
  const [, {machineCenterId}] = queryKey;
  return axios.get(`${SST_API_PATH_MACHINE_CENTERS}/${machineCenterId}/runs`).then(res => res.data);
}

export const updateMachineCenter = (machineCenter) => {
  return axios.put(`${SST_API_PATH_MACHINE_CENTERS}/${machineCenter.id}`, machineCenter).then(res => res.data);
}

export const createMachineCenter = (machineCenter) => {
  return axios.post(`${SST_API_PATH_MACHINE_CENTERS}`, machineCenter).then(res => res.data);
}