import { CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { SiteContext } from '../../Context'
import {
  getCustomerLines,
  getCustomerPlants,
} from '../../query/queries'
import LinemapCard from '../../shared/components/Cards/LinemapCard'
import SearchBar from '../../shared/components/SearchBar/SearchBar'
import SSTAccordion from '../../shared/components/SSTAccordion/SSTAccordion'
import {
  DISPLAY_TYPE_ACCORDION,
  DISPLAY_TYPE_GRID,
} from '../../shared/Utilities'
import { clearQueue } from '../../utils/LinemapFetcher'

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: 10,
  },
  accordionCardContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(480px, 1fr))',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gap: '1rem',
    position: 'relative',
    maxHeight: '650px',
    overflowY: 'auto',
  },
  gridCardContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(620px, 1fr))',
    gap: '1rem',
    placeItems: 'center',
    padding: '1rem',
    paddingTop: '0',
    margin: '1rem',
  },
  loadingContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  toolbar: {
    padding: '16px 32px 0px',
  },
  customerSelect: {
    padding: '10px',
  },
  customerLoading: {
    marginLeft: '10px',
    display: 'inline-block',
    width: '150px',
    marginBottom: '2px',
  },
  accordionContainer: {
    padding: '5px 10px',
  },
})

const Dashboard = () => {
  const { currentCustomer } = useContext(SiteContext)
  const [layoutType, setLayoutType] = useState(localStorage.getItem('viewType'))
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const [previousAccessedPage, setPreviousAccessedPage] = useState('')

  const onUpdateViewType = (layoutType) => {

    /**
     * Clear LinemapFetcher queue. The same set of LinemapCard child components is unmounted and remounted, every
     * time the layoutType is changed, therefore, clear the queue to ensure we're not queueing duplicate items and
     * duplicating work...
     */
    clearQueue();

    setLayoutType(layoutType)
    localStorage.setItem('viewType', layoutType)
  }

  const { isLoading: isLoadingPlants, data: plants = [] } = useQuery(
    ['plants', { customerId: currentCustomer }],
    getCustomerPlants
  )
  const { isLoading: isLoadingLines, data: lines = [] } = useQuery(
    ['lines', { customerId: currentCustomer }],
    getCustomerLines,
    { enabled: plants.length > 0 }
  )

  const accordionList = plants
    .filter((x) => !!x)
    .flat()
    .map((plant) => ({
      plant,
      plantLines: lines.filter((line) => line.plantId === plant.id),
    }))

  const gridList = lines
    .filter((x) => !!x)
    .flat()
    .map((line) => ({
      line: line,
      plant: plants.find((x) => x.id === line.plantId),
    }))

  useEffect(() => {
    document.title = 'Dashboard'
    if (!layoutType) {
      setLayoutType(DISPLAY_TYPE_ACCORDION)
    }
    if (!!history.location?.state?.previousAccessedPage) {
      setPreviousAccessedPage(history.location.state.previousAccessedPage)
    }
  }, [layoutType, history.location?.state?.previousAccessedPage])

  useEffect(() => {
    if (previousAccessedPage) {
      if (previousAccessedPage !== 'AuthCheck') {
        // The user has access to the page via their permissions but no data is present
        enqueueSnackbar(
          `User does not have permission to access the page: ${previousAccessedPage}`,
          { variant: 'warning' }
        )
      } else {
        // The user doesn't have the appropriate permission to access this page
        enqueueSnackbar(
          'Authorization check failed. Redirecting to Dashboard.',
          { variant: 'warning' }
        )
      }
    }
  }, [previousAccessedPage, enqueueSnackbar])

  const classes = useStyles()
  const isLoading = isLoadingPlants || isLoadingLines
  const expandOnload = !isLoading && plants.length === 1

  function cardMap(cards, plant) {
    return (
      <div className={classes.accordionCardContainer}>
        {cards.map((card) => {
          return (
            <LinemapCard
              key={card.id}
              line={card}
              plant={plant}
              viewType={DISPLAY_TYPE_ACCORDION}
            />
          )
        })}
      </div>
    )
  }

  return (
    <div data-testid="dashboard-page" className={classes.root}>
      <SearchBar
        layoutType={layoutType}
        title="Dashboard"
        onUpdateDisplay={onUpdateViewType}
      />
      {isLoading && (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      )}
      {!isLoading && layoutType === DISPLAY_TYPE_GRID && (
        <div className={classes.gridCardContainer}>
          {gridList.map((card) => {
            return (
              <LinemapCard
                key={card.line.id}
                line={card.line}
                plant={card.plant}
                viewType={DISPLAY_TYPE_GRID}
              />
            )
          })}
        </div>
      )}
      {!isLoading &&
        layoutType === DISPLAY_TYPE_ACCORDION &&
        accordionList.map((accordion, i) => {
          let plantDisabled = accordion.plantLines?.length === 0
          let focusOnExpand = i === accordionList.length - 1 ? true : false
          return (
            <div
              className={classes.accordionContainer}
              key={`plant-${accordion.plant.id}-container`}
            >
              <SSTAccordion
                id={accordion.plant.id}
                name={accordion.plant.name}
                expandOnInit={expandOnload}
                disabled={plantDisabled}
                tooltipEnabled={plantDisabled}
                tooltipLabel={'Plant has no lines associated'}
                storeLocalState={true}
                content={cardMap(accordion.plantLines, accordion.plant)}
                focusOnExpand={focusOnExpand}
              />
            </div>
          )
        })}
    </div>
  )
}

export default Dashboard
