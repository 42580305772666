import * as React from "react";
import useStyles from './Styles.js';
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import {SiteContext} from "../../../Context";
import translations from '../../../translations/en.json';
import {
  deepEqual,
  handlePermissionRedirect,
  PERMISSION_METHOD_GET,
} from '../../../shared/Utilities'
import PageHeader from '../../../shared/components/PageHeader/PageHeader'
import PageFooter from '../../../shared/components/PageFooter/PageFooter'
import ProgressButton, { PROGRESS_BUTTON_VARIANTS } from '../../../shared/components/ProgressButton/ProgressButton'
import { SST_PAGE_LIST_MACHINE_CENTERS } from '../../../Constants'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getEntityPermissions } from '../../../query/entities/auth'
import { useRouteMatch } from 'react-router-dom'
import {
  getMachineCenter,
  updateMachineCenter
} from '../../../query/entities/machineCenters'
import SaveConfirmationDialog from '../../../shared/components/Dialogs/SaveConfirmationDialog/SaveConfirmationDialog'
import { useSnackbar } from 'notistack'
import { TextField, Typography } from '@mui/material'
import QuantifeelSvgIcon from '../../../shared/components/QuantifeelSvgIcon/QuantifeelSvgIcon'
import {ReactComponent as DeleteIcon} from '../../../img/icons/toggle_x.svg'
import {ReactComponent as AddIcon} from '../../../img/icons/add role.svg'
import FullScreenCircularProgress from '../../../shared/components/FullScreenCircularProgress'
import Form from 'react-bootstrap/Form'
import { getDuplicateMetadataKeys } from './Helpers'
import ReadOnlyNotification from '../../../shared/components/ReadOnlyNotification'
import { getLine } from '../../../query/entities/lines'

const pageTitle = translations.pages.machineCenterSettings.title;

const acceptablePagePermission = [
  {entity: 'Line', method: PERMISSION_METHOD_GET, modifier: ''},
  {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
]

const MachineCenterSettings = (props) => {

  const {
    history
  } = props;

  const {currentCustomer, hasPermission} = useContext(SiteContext);

  const match = useRouteMatch();
  const queryClient = useQueryClient();
  const {enqueueSnackbar} = useSnackbar();

  const emptyMetadataObject = {key: '', value: ''}

  const formRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [machineCenterId, setMachineCenterId] = useState(null);
  const [isSaveConfirmationDialogOpen, setIsSaveConfirmationDialogOpen] = React.useState(false);

  const [activeMachineCenterMetadata, setActiveMachineCenterMetadata] = useState([{...emptyMetadataObject}]); // Spread emptyMetadataObject to create copy and avoid reference issues...
  const [initialMachineCenterMetadata, setInitialMachineCenterMetadata] = useState([{...emptyMetadataObject}]); // Spread emptyMetadataObject to create copy and avoid reference issues...

  // ------------------------------
  // -- BEGIN useQuery / useMemo --
  // ------------------------------

  const {isLoading: isLoadingCustomerEntityPermissions, data: customerEntityPermissions} = useQuery(
    ['entityPermissions', currentCustomer, 'machineCenter', {ids: [machineCenterId]}],
    getEntityPermissions,
    { enabled: !!machineCenterId }
  );

  const {isLoading: isLoadingMachineCenter, data: machineCenter= {} } = useQuery(
    ['machineCenter', {machineCenterId: machineCenterId}], // This query key is invalidated on mutate, to force refetch of data...
    getMachineCenter,
    { enabled: !!machineCenterId }
  );

  const {isLoading: isLoadingLine, data: line= {} } = useQuery(
    ['line', {lineId: machineCenter.lineId}],
    getLine,
    { enabled: !!machineCenter?.lineId }
  );

  /**
   * Mutator for updating machine center settings, given machine center settings...
   */
  const {mutate: mutateUpdateMachineCenter, isLoading: isUpdatingMachineCenter} = useMutation(updateMachineCenter, {
    onSuccess: (data, variables) => {

      // Close save confirmation dialog...
      setIsSaveConfirmationDialogOpen(false);

      // Invalidating useQuery key, to force refetch of data, following upsert...
      queryClient.invalidateQueries(['machineCenter', {machineCenterId: machineCenterId}]);

      // Show success...
      enqueueSnackbar(translations.pages.machineCenterSettings.machineCenterSettingsUpdated, {variant: 'success'});
    },
    onError: ({response: {data}}) => {

      // Close save confirmation dialog...
      setIsSaveConfirmationDialogOpen(false);

      // Show error...
      enqueueSnackbar(data.message, {variant: 'error'});
    }
  });

  /**
   * Given changes to machineCenterId, permissions
   * Set isReadOnly...
   */
  const isReadOnly = useMemo(() => {
    return (
      !customerEntityPermissions?.machinecenter[machineCenterId]?.update
    )
  }, [machineCenterId, customerEntityPermissions])

  /**
   * Given changes to activeMachineCenterMetadata, initialMachineCenterMetadata
   * Determine if machine settings are dirty...
   */
  const isMachineCenterSettingsDirty = useMemo(() => {
    // If not deepEqual, then dirty...
    return !deepEqual(activeMachineCenterMetadata, initialMachineCenterMetadata);
  }, [activeMachineCenterMetadata, initialMachineCenterMetadata]);

  // ----------------------------
  // -- END useQuery / useMemo --
  // ----------------------------

  // ----------------------
  // -- BEGIN useEffects --
  // ----------------------

  /**
   * On component mount...
   */
  useEffect(() => {

    // Validate permissions...
    handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission)

    // Set page title..
    document.title = pageTitle;

    // Set machineCenterId...
    const machineCenterId = match.params.machineCenterId;
    setMachineCenterId(machineCenterId);

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Given changes to useQuery loading states...
   * Set isLoading...
   */
  useEffect(() => {
    if ( isLoadingCustomerEntityPermissions ||
         isLoadingMachineCenter ||
         isLoadingLine) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoadingCustomerEntityPermissions, isLoadingMachineCenter, isLoadingLine]);

  /**
   * Given changes to machineCenter...
   * Set activeMachineCenterMetadata, initialMachineCenterMetadata...
   */
  useEffect(() => {

    // If the machineCenter has metadata, set activeMachineCenterMetadata, initialMachineCenterMetadata, from default...
    if ( machineCenter?.machineCenterMetadata &&
         machineCenter.machineCenterMetadata.length !== 0) {

      setActiveMachineCenterMetadata(
        [...machineCenter.machineCenterMetadata] // Copy to avoid reference issues, between activeMachineCenterMetadata and initialMachineCenterMetadata...
          .sort((a, b) => a.key.localeCompare(b.key)) // Sort by key, in ascending order...
      )

      setInitialMachineCenterMetadata(
        [...machineCenter.machineCenterMetadata] // Copy to avoid reference issues, between activeMachineCenterMetadata and initialMachineCenterMetadata...
          .sort((a, b) => a.key.localeCompare(b.key)) // Sort by key, in ascending order...
      )
    }
  }, [machineCenter])

  /**
   * Give changes to activeMachineCenterMetadata...
   * If activeMachineCenterMetadata is empty, add an empty metadata object, to ensure there's always one KVP row ready for edit...
   */
  useEffect(() => {
    if (activeMachineCenterMetadata.length === 0) {
      setActiveMachineCenterMetadata( metadata => [...metadata, {...emptyMetadataObject}])
    }
  }, [activeMachineCenterMetadata]) // eslint-disable-line react-hooks/exhaustive-deps

  // --------------------
  // -- END useEffects --
  // --------------------

  // --------------------------
  // -- BEGIN event handlers --
  // --------------------------

  const onBack = () => {
    // If there is a page in history, go back, else, go to /list-machine-centers...
    return !!(history.location.key) ? history.goBack() : history.push(`/${SST_PAGE_LIST_MACHINE_CENTERS}`)
  }

  const onAddRow = () => {
    const updatedActiveMachineCenterMetadata = JSON.parse(JSON.stringify(activeMachineCenterMetadata)); // Deep copy, since object has deep nesting...
    updatedActiveMachineCenterMetadata.push({...emptyMetadataObject});
    setActiveMachineCenterMetadata(updatedActiveMachineCenterMetadata)
  }

  const onRemoveRow = (rowIndex) => {
    const updatedActiveMachineCenterMetadata = activeMachineCenterMetadata.filter((metadata, index) => index !== rowIndex);
    setActiveMachineCenterMetadata(updatedActiveMachineCenterMetadata);
  }

  const onRowKeyChange = (rowIndex, key) => {
    const updatedActiveMachineCenterMetadata = JSON.parse(JSON.stringify(activeMachineCenterMetadata)); // Deep copy, since object has deep nesting...
    updatedActiveMachineCenterMetadata[rowIndex].key = key;
    setActiveMachineCenterMetadata(updatedActiveMachineCenterMetadata);
  }

  const onRowValueChange = (rowIndex, value) => {
    const updatedActiveMachineCenterMetadata = JSON.parse(JSON.stringify(activeMachineCenterMetadata)); // Deep copy, since object has deep nesting...
    updatedActiveMachineCenterMetadata[rowIndex].value = value;
    setActiveMachineCenterMetadata(updatedActiveMachineCenterMetadata);
  }

  const onSubmit = (event) => {

    // Get form validity...
    const isFormValid = formRef.current.checkValidity();

    // Check form validity...
    if (isFormValid) {
      // Valid. Show save confirmation dialog...
      setIsSaveConfirmationDialogOpen(true)
    } else {
      // Invalid. Show error messages...
      formRef.current.reportValidity();
    }
  }

  const onSave = () => {

    // Validate...
    // a. Validate that there are no duplicate metadata keys...
    const duplicateMetadataKeys = getDuplicateMetadataKeys(activeMachineCenterMetadata);
    if (duplicateMetadataKeys.length > 0) {
      enqueueSnackbar(`${translations.pages.machineCenterSettings.duplicateKeysErrorMsg} ${duplicateMetadataKeys.join(', ')}`, {variant: 'error'});
      setIsSaveConfirmationDialogOpen(false)
      return;
    }

    // Upsert machine center...
    const updatedMachineCenter = {
      ...machineCenter,
      machineCenterTypeId: machineCenter.machineCenterType?.id, // Set machineCenterTypeId, since required by update API, lest the API interpret our request as including setting machineCenterType to null...
      machineCenterMetadata: activeMachineCenterMetadata
    }
    // a. Upsert...
    mutateUpdateMachineCenter(updatedMachineCenter)
  }

  // ------------------------
  // -- END event handlers --
  // ------------------------

  // -------------------
  // -- BEGIN renders --
  // -------------------

  const classes = useStyles()

  const renderHeader = () => {
    return (
      <PageHeader
        onBack={onBack}
        pageTitle={`${machineCenter.name} ${translations.pages.lineSettings.settings}`}
      />
    )
  }

  const renderFooter = () => {
    return (
      <PageFooter>
        <div className={classes.footerContentContainer}>

          {/* Cancel */}
          <div className={classes.footerContentContainerSubContainer}>
            <ProgressButton
              variant={PROGRESS_BUTTON_VARIANTS.SECONDARY}
              text={translations.common.cancel}
              onClick={onBack}
            />
          </div>

          {/* Save */}
          <div className={classes.footerContentContainerSubContainer}>
            <ProgressButton
              variant={PROGRESS_BUTTON_VARIANTS.PRIMARY}
              text={translations.common.save}
              disable={isReadOnly || !isMachineCenterSettingsDirty}
              onClick={onSubmit}
            />
          </div>

        </div>
      </PageFooter>
    )
  }

  const renderReadOnlyNotification = () => {
    return (
      <div className={classes.readOnlyNotificationContainer}>
        <ReadOnlyNotification message={translations.common.insufficientPermissionsToUpdateSettings}/>
      </div>
    )
  }

  const renderTemporaryTemplateKeysButton = () => {
    return (
      <div style={{
        marginTop: 26,
        marginLeft: 26
      }}>
        <ProgressButton
          style={{
            display: 'flex',
            width: 'unset',
          }}
          disable={isReadOnly}
          variant={PROGRESS_BUTTON_VARIANTS.PRIMARY}
          text={"Add Pharmaceutical Capper Template Keys"}
          onClick={() => {
            const updatedActiveMachineCenterMetadata = JSON.parse(JSON.stringify(activeMachineCenterMetadata)); // Deep copy, since object has deep nesting...
            updatedActiveMachineCenterMetadata.push({key: 'make', value: ''});
            updatedActiveMachineCenterMetadata.push({key: 'model', value: ''});
            updatedActiveMachineCenterMetadata.push({key: 'capperType', value: ''});
            updatedActiveMachineCenterMetadata.push({key: 'capperNumberOfHeads', value: ''});
            updatedActiveMachineCenterMetadata.push({key: 'isCapperHeadOrderAscending', value: ''});
            setActiveMachineCenterMetadata(updatedActiveMachineCenterMetadata);
          }}
        />
      </div>
    )
  }

  const renderMetadataTable = () => {
    return (
      <div className={classes.metadataTableContainer}>

        {/* Header */}
        <div className={classes.metadataTableHeader}>
          <Typography variant="h6">{translations.pages.machineCenterSettings.metadata}</Typography>
          <AddRow
            disabled={isReadOnly}
            onClick={onAddRow}
          />
        </div>

        {/* Table */}
        <div className={classes.metadataTable}>

          {/* Table Header */}
          <div className={classes.metadataTableRow}>
            <Typography className={classes.metadataCell}>{translations.pages.machineCenterSettings.metadataTableKey}</Typography> {/* Key */}
            <Typography className={classes.metadataCell}>{translations.pages.machineCenterSettings.metadataTableValue}</Typography> {/* Value */}
            <div className={classes.metadataActionsCell}/> {/* Actions */} {/* Including this <div/> to simplify spacing. */}
          </div>

          {/* Table Rows */}
          <Form
            className={classes.metadataTableForm}
            id={"metadataForm"}
            autoComplete={"off"}
            ref={formRef}
            onSubmit={onSubmit}
          >

            {activeMachineCenterMetadata.map((metadata, index) => (

              <div key={index} className={classes.metadataTableRow}>

                {/* Key */}
                <TextField
                  required
                  disabled={isReadOnly}
                  className={`${classes.metadataCell} ${classes.metadataDataCell}`}
                  InputProps={{
                    className: classes.metadataCellInput
                  }}
                  placeholder={translations.pages.machineCenterSettings.keyPlaceholder}
                  value={metadata.key}
                  onChange={(event) => onRowKeyChange(index, event.target.value)}
                />

                {/* Value */}
                <TextField
                  required
                  disabled={isReadOnly}
                  className={`${classes.metadataCell} ${classes.metadataDataCell}`}
                  InputProps={{
                    className: classes.metadataCellInput
                  }}
                  placeholder={translations.pages.machineCenterSettings.valuePlaceholder}
                  value={metadata.value}
                  onChange={(event) => onRowValueChange(index, event.target.value)}
                />

                {/* Actions */}
                <div className={classes.metadataActionsCell}>
                  {/* We only allow the removal of rows that have not yet been persisted, represented by a missing metadata.id
                      Therefore, only show remove button for rows that have not yet been persisted / have missing metadata.id */}
                  { !metadata.id && (
                    <QuantifeelSvgIcon
                      disabled={isReadOnly}
                      className={classes.deleteIcon}
                      component={DeleteIcon}
                      viewBox="0 0 16 16"
                      tooltipTitle={translations.pages.machineCenterSettings.removeRow}
                      onClick={() => onRemoveRow(index)}
                    />
                  )}
                </div>
              </div>

            ))}

          </Form>

        </div>
      </div>
    )
  }

  const AddRow = ({onClick}) => {
    return (
      <QuantifeelSvgIcon
        disabled={isReadOnly}
        component={AddIcon}
        viewBox={'0 0 48 48'}
        className={classes.addIcon}
        tooltipTitle={translations.pages.machineCenterSettings.addRow}
        onClick={onClick}
      />
    )
  }

  const renderSaveConfirmationDialog = () => {
    return (
      <SaveConfirmationDialog
        open={isSaveConfirmationDialogOpen}
        isSaving={isUpdatingMachineCenter}
        onClose={() => setIsSaveConfirmationDialogOpen(false)}
        onCancel={() => setIsSaveConfirmationDialogOpen(false)}
        onSave={onSave}
      />
    )
  }

  return (
    <>
      {isLoading && <FullScreenCircularProgress/>}

      <div className={classes.root}>
        {renderHeader()}
        {isReadOnly && renderReadOnlyNotification()}
        {line.isPharma && renderTemporaryTemplateKeysButton()}
        {renderMetadataTable()}
        {renderFooter()}
        {renderSaveConfirmationDialog()}
      </div>
    </>
  );
};

export default MachineCenterSettings;